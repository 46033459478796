<template>
  <b-modal
    id="transactions-create-modal"
    :ok-disabled="!hasName"
    :ok-title="$t('form.actions.save')"
    ok-only
    centered
    size="lg"
    @ok="createTransaction()"
  >
    <template #modal-header>
      <language-selector-header 
        :title="transaction.key ? $t('backoffice.payments.modal.edit') : $t('backoffice.payments.modal.new')" 
        @closeModal="closeModal" 
        @selectLanguage="(language)=>selectedLanguage = language" 
      />
    </template>
    <b-form>
      <b-row>
        <!--  title -->
        <b-col cols="12">
          <b-form-group>
            <label for="name" class="font-weight-bold">
              {{ $t('forms.title') }}*
            </label>
            <b-form-input
              id="name"
              v-model="transaction.name[selectedLanguage]"
              type="text"
            />
          </b-form-group>
        </b-col>
        <!-- type -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="type" class="font-weight-bold">
              {{ $t('settings.mainData.type') }}*
            </label>
            <v-select
              id="type"
              v-model="typeSelected"
              :clearable="false"
              :reduce="(type) => type.code"
              :options="types"
              label="label"
            />
          </b-form-group>
        </b-col>
        <!-- file -->
        <b-col cols="12">
          <b-form-group 
            :label="$t('backoffice.onboarding-form.form.file')" 
            label-for="file" 
            class="d-inline-block w-100"
          >
            <file-preview
              v-if="transaction.fileURL"
              :src="getImageResource(transaction.fileURL)"
              mime-type="file"
              cancelable
              @cancel="transaction.fileURL = null"
            />
            <file-upload
              v-else
              v-model="file"
              type="file"
              cancelable
              :placeholder="$t('form-create-item.file-button')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="file = null"
            />
          </b-form-group>
        </b-col>
        <!-- StartDate -->
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="startDate" class="font-weight-bold">
              {{ $t("backoffice.settings.date") }}
            </label>
            <VueCtkDateTimePicker
              id="startDate"
              v-model="transaction.startDate"
              :locale="locale"
              format="YYYY-MM-DD HH:mm"
              :label="$t('datepicker.select-label')"
              color="var(--primary)"
              no-button-now
              button-color="var(--primary)"
              class="date-picker"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import vSelect from 'vue-select';
import { quillEditor } from 'vue-quill-editor';
import FileUpload from '@core/components/files/FileUpload.vue';
import FilePreview from '@core/components/files/FilePreview.vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'ModalCreateTransaction',
  components: {
    LanguageSelectorHeader,
    vSelect,
    quillEditor,
    FilePreview,
    FileUpload,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      typeSelected: '',
      selectedLanguage: '',
      file: null,
    };
  },
  computed: {
    types() {
      return [
        { label: 'Organization', code: 'organization' },
        { label: 'General', code: 'general' },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    hasName() {
      return Object.values(this.transaction?.name).some((item) => item != '');
    },
  },
  created() {
    this.selectedLanguage = this.locale;
    this.typeSelected = this.transaction.key ? this.transaction.type : this.types[0].code;
  },
  methods: {
    async createTransaction() {
      const file = {};
      const itemDelete = {};
      this.file ? file.fileURL = this.file : (file.fileURL = null, itemDelete.deletefileURL = true);

      const requestConfig = {
        name: this.transaction.name,
        description: this.transaction.description,
        startDate: this.transaction.startDate,
        endDate: this.transaction.endDate,
        isCompletable: this.transaction.isCompletable,
        scoreToPass: this.transaction.scoreToPass,
        type: this.typeSelected,
        ...itemDelete,
      };
      try {
        let response = null;
        if (this.transaction.key) {
          //Edit
          requestConfig.key = this.transaction.key;
          response = await this.$store.dispatch('editItem', {
            item: {
              itemType: 'transactions',
              requestConfig,
            },
            file,
          });
        } else {
          //Create 
          response = await this.$store.dispatch('createItem', {
            item: {
              itemType: 'transactions',
              orderByDate: 1,
              requestConfig,
            },
            file,
            // file: this.file,
          });
          //console.log('response', response);
        }
        if(response.status === 200){
          this.notifySuccess(this.$t('success-message.general-success-action'));
        }else{
          //console.log('bad response:', response.statusText);
          const errorText = this.$t('error-message.general-error') + ' \n Reason: ' + response.statusText;
          this.notifyError(errorText);
        }
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    getImageResource(file) {
      return getImageResource(file);
    },
    closeModal() {
      this.$bvModal.hide('transactions-create-modal');
    },
  },

};
</script>

<style>

</style>
