var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"hover":"","busy":_vm.busy,"items":_vm.items,"fields":_vm.fieldsList,"responsive":"","sticky-header":'calc(100vh - 330px)'},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center ml-50 mt-25",staticStyle:{"min-width":"200px"}},[[_c('b-link',{staticClass:"text-dark d-flex align-items-center",attrs:{"href":_vm.getCollectiveUrl(item.community),"target":"_blank"}},[_c('div',{staticClass:"mb-75 d-flex align-items-center w-100"},[_c('collective-logo',{attrs:{"logo":item.community.logoURL,"size":30}}),_c('div',{staticClass:"ml-50"},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.translate(item.community.name))+" ")]),(item.community.headline)?_c('p',{staticClass:"small mb-0 mt-n25 text-muted"},[_vm._v(" "+_vm._s(_vm.translate(item.community.headline))+" ")]):_vm._e()])],1)])]],2)]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"120px"}},[_c('b-badge',{staticClass:"text-capitalize mb-0 mt-75 check-button",attrs:{"pill":"","variant":_vm.getColorSpace(item.community.mainType)}},[_vm._v(" "+_vm._s(_vm.getStatusSpace(item.community.mainType))+" ")])],1)]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-75 mr-25 ml-50 align-items-center",staticStyle:{"min-width":"160px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.timestampToTime(item.createdAt))+" ")])])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"min-width":"120px"}},[(item.status)?_c('b-badge',{staticClass:"text-capitalize mb-0 ml-1 mt-75 check-button",attrs:{"pill":"","variant":_vm.getColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")]):_c('p',{staticClass:"text-muted mb-0 ml-2 pl-75 mt-75"},[_vm._v("---")])],1)]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.price)+" "+_vm._s(item.currency.symbol))])])]}},{key:"cell(real)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[(item.hasDiscount)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.price + item.discountAbsolute)+_vm._s(item.currency.symbol)+" ")]):_c('p',[_vm._v("---")])])]}},{key:"cell(discount)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end",staticStyle:{"min-width":"120px"}},[(item.hasDiscount)?_c('p',{staticClass:"mb-0"},[_vm._v(" - "+_vm._s(item.discountAbsolute)+_vm._s(item.currency.symbol)+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v("---")])])]}},{key:"cell(ref)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 h-100 d-flex pl-1 ml-50 align-items-center",staticStyle:{"min-width":"120px"}},[_c('p',[_vm._v(_vm._s(item.ref))])])]}},{key:"custom-foot",fn:function(ref){
var columns = ref.columns;
return _vm._l((columns),function(columnIndex){return _c('b-td',{key:columnIndex,staticClass:"totals"},[(columnIndex === 1)?_c('p',{staticClass:"font-weight-extrabold mb-0 font-medium-2 ml-2 text-white"},[_vm._v(" "+_vm._s(_vm.$t('backoffice.payments.table.totals'))+" ")]):_vm._e(),([3, 4, 5].includes(columnIndex))?_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-end"},[(columnIndex === 3)?_c('p',{staticClass:"mb-0 text-white"},[_vm._v(" "+_vm._s(_vm.totals.price.toFixed(2))+" "+_vm._s(_vm.items[0].currency.symbol)+" ")]):_vm._e(),(columnIndex === 4)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.totals.real.toFixed(2))+" "+_vm._s(_vm.items[0].currency.symbol)+" ")]):_vm._e(),(columnIndex === 5)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" - "+_vm._s(_vm.totals.discount.toFixed(2))+" "+_vm._s(_vm.items[0].currency.symbol)+" ")]):_vm._e()]):_vm._e()])})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }