<template>
  <member-setting-container>
    <b-card
      no-body
      :class="isMobile ? 'p-2' : 'p-3'"
      :style="
        isMobile
          ? 'min-height: calc(100vh - 100px)'
          : 'min-height: calc(100vh - 125px)'
      "
    >
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("backoffice.payments.title") }}</h4>
        <!--div class="d-flex">
          <b-button
            variant="primary"
            class="font-weight-bold"
            size="sm"
            @click="openModal"
          >
            {{ $t('backoffice.payments.new') }}
          </b-button>
        </div-->
      </div>
      <div v-if="isLoading" class="h-100 d-flex align-items-center">
        <b-spinner class="d-block mx-auto mt-3" variant="primary" />
      </div>
      <div v-else>
        <app-scroll v-if="items && items.length > 0">
          <my-payments-table-mobile
            v-if="isMobile"
            :busy="isLoading || isLoadingNextPage"
            :items="items"
            :totals="totals"
          />
          <my-payments-table-web
            v-else
            :busy="isLoading || isLoadingNextPage"
            :items="items"
            :totals="totals"
          />
        </app-scroll>
        <div
          v-else
          class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh"
        >
          <b-img :src="paymentsPlaceholder" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{
                $t("available.message", {
                  itemName: $t("backoffice.payments.title"),
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <modal-create-transaction :transaction="transaction" />
  </member-setting-container>
</template>

<script>
import MemberSettingContainer from "@/views/myself/components/MemberSettingContainer.vue";
import PaymentsPlaceholder from "@/assets/images/placeholders/light/payments.svg";
import AppScroll from "@/@core/components/app-scroll/AppScroll.vue";
import MyPaymentsTableWeb from "@/views/myself/components/my-payments/MyPaymentsTableWeb.vue";
import MyPaymentsTableMobile from "@/views/myself/components/my-payments/MyPaymentsTableMobile.vue";
import ModalCreateTransaction from '@/views/myself/transactions/components/ModalCreateTransaction.vue';

export default {
  name: "MyPayments",
  components: {
    MemberSettingContainer,
    AppScroll,
    MyPaymentsTableWeb,
    MyPaymentsTableMobile,
    ModalCreateTransaction
  },
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      totals: {
        price: 0,
        real: 0,
        discount: 0,
      },
      transaction: {
        name: {
          ca: '',
          es: '',
          en: '',
        },
        description: {
          ca: '',
          es: '',
          en: '',
        },
      },
    };
  },
  computed: {
    paymentsPlaceholder() {
      return PaymentsPlaceholder;
    },
    itemsData() {
      return this.$store.getters.payments?.userPayments;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    items() {
      return this.itemsData?.unpaginated;
    },
  },
  async created() {
    await this.fetchData();
    this.calculateTotals();
  },
  methods: {
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "payments",
        storedKey: "userPayments",
        page: 1,
        perPage: 20,
        forceAPICall: force,
        requestConfig: {
          isForUser: true,
          forMain: true,
        },
      });
      this.isLoading = false;
    },
    calculateTotals() {
      if (this.items.length > 0) {
        this.items.forEach((item) => {
          this.totals.price += item.price;
          if (item.hasDiscount) {
            this.totals.discount += item.discountAbsolute;
            this.totals.real += item.price + item.discountAbsolute;
          }
        });
      }
    },
    openModal() {
      this.transaction = {
        name: {
          ca: '',
          es: '',
          en: '',
        },
        description: {
          ca: '',
          es: '',
          en: '',
        },
      };
      this.$bvModal.show('transactions-create-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}
</style>
